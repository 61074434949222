.introduction{

	.page-header{

		.textarea{

			h1{
				margin: 0 -5px;
			}
		}

		.img{
			max-width: 166px;
		}
	}

	&__block{
		margin: 0;
		padding: 33px 15px 50px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 1100px;
		}
	}

	//-------------------------------------
	&__lead{
		margin-bottom: 26px;
	}

	//-------------------------------------
	&__list{
		display: flex;
		align-items: flex-start;
		margin: 20px 0;

		&:not(:last-child){
			position: relative;

			&::after{
				content: "";
				display: block;
				width: 2px;
				height: calc(100% + 15px);
				border-right: 2px solid #C0DBEC;
				position: absolute;
				left: 58px;
				top: 5px;
				z-index: -1;
			}
		}

		dt{
			width: 60px;
			color: #0072BC;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: 0.15em;
			line-height: 1.5;
			position: relative;

			&::after{
				content: "";
				display: block;
				width: 2px;
				height: 40px;
				border-right: 2px solid #0072BC;
				position: absolute;
				right: 0;
				top: 5px;
			}

			em{
				display: block;
				margin-top: 3px;
				margin-left: 2px;
				color: #0072BC;
				font-size: 25px;
				font-style: normal;
				line-height: 1;
			}
		}

		dd{
			padding-left: 20px;
			width: calc(100% - 60px);

			h3{
				font-size: 16px;
				font-weight: 700;
				line-height: 1.68;
			}

			p{
				margin-top: 5px;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.92;
			}
		}
	}

	//-------------------------------------
	&__ttl{
		margin-top: 44px;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
	}

	//-------------------------------------
	&__img1{
		margin: 20px auto 0;
		padding: 0;

		figcaption{
			margin-top: 13px;
			font-size: 13px;
			text-align: right;
		}
	}


}
@media screen and (min-width:768px){
	.introduction{

		.page-header{

			.textarea{

				h1{
					margin: 0;
				}
			}

			.img{
				max-width: 326px;
			}
		}

		&__block{
			padding: 80px 15px 100px;
		}

		//-------------------------------------
		.cont1{

		}

		//-------------------------------------
		&__lead{
			margin-top: -5px;
			margin-bottom: 50px;
			text-align: center;
		}

		//-------------------------------------
		&__list{
			margin: 47px auto;
			max-width: 980px;

			&:not(:last-child){
	
				&::after{
					height: calc(100% + 75px);
					left: 73px;
					top: 15px;
				}
			}

			dt{
				padding-top: 10px;
				padding-left: 2px;
				width: 75px;

				&::after{
					height: 42px;
					top: 15px;
				}

				em{
					margin-left: 7px;
				}
			}

			dd{
				padding-left: 30px;
				width: calc(100% - 75px);

				h3{
					font-size: 18px;
				}

				p{
					margin-top: 10px;
					font-size: 16px;
				}
			}

		}

		//-------------------------------------
		&__ttl{
			margin-top: 96px;
			font-size: 24px;
		}

		//-------------------------------------
		&__img1{
			margin-top: 40px;
			padding: 0 5px;
			max-width: 760px;

			figcaption{
				margin-top: 23px;
			}
		}

	}
}